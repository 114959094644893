import React, { useState, useEffect } from 'react';
import backend from '../../services/backend';
import moment from 'moment'
import constants from '../../constants'
import './ChatTranscriptView.css'
const {__version__} = require('../../__version__');


const ChatTranscriptView = (props) => {
    const { recordings, id } = props
    const [chatTranscript, setChatTranscript] = useState()

    useEffect(() => { // init
        const fetchMetadata = async () => {
            try {
                const response = await backend.getConversationMetadata(sessionStorage.getItem(constants.GC_TOKEN), id, recordings)
                const transcript = response.recordings[0].metadata.map(x => {
                    if (x.from && x.body && x.bodyType === 'standard')
                        return `${moment(x.utc).format("MMM Do YYYY, h:mm:ss a")} ${x.from}: ${x.body}`
                    return undefined
                }).filter(x => x).join('\r\n')

               
                setChatTranscript(transcript)
            } catch (error) {
               
            }
        }
       
        fetchMetadata()
    }, [])

    return (
        <div>
            {chatTranscript &&
                <div className="container container-chat-transcript mx-2">
                    <textarea>
                        {chatTranscript}
                    </textarea>
                </div>

            }
        </div>
    );
}

export default ChatTranscriptView;