import './PureCloudLogin.css'
import React, { useEffect, Fragment } from 'react'
import config from '../../../config'
import constants from '../../../constants'

const PureCloudLogin = () => {
    useEffect(() => {
       
       
        let sessionEnv = sessionStorage.getItem(constants.GC_ENVIRONMENT);
       
        if(sessionEnv){
            //do nothing
            sessionStorage.setItem(constants.GC_ENVIRONMENT,sessionEnv);
        }else{
            sessionStorage.setItem(constants.GC_ENVIRONMENT,constants.GC_ENV_AP_NORTHEAST_1);
        }
        const queryStringData = {
            response_type: 'token',
            client_id: config.clientID,
            redirect_uri: `${window.location.protocol}//${window.location.host}`
        }
        const param = Object.keys(queryStringData).map(k => `${encodeURIComponent(k)}=${encodeURIComponent(queryStringData[k])}`).join('&')
       
        window.location = `https://login.${sessionStorage.getItem(constants.GC_ENVIRONMENT)}/oauth/authorize?${param}`
    }, [])

    return (<Fragment></Fragment>)
}

export default PureCloudLogin