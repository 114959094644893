import config from '../config'

export const getParameterByName = (name) => {
    name = name.replace(/[[]/, "\\[").replace(/[\]]/, "\\]")
   
    const regex = new RegExp("[\\#&]" + name + "=([^&#]*)"),
        results = regex.exec(window.location.search)
    return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '))
}

export const getUrlParameter = (sParam) => {
   
    var sPageURL = decodeURIComponent(window.location.search.substring(1)),
        sURLVariables = sPageURL.split('&'),
        sParameterName,
        i;

    for (i = 0; i < sURLVariables.length; i++) {
        sParameterName = sURLVariables[i].split('=');

        if (sParameterName[0] === sParam) {
            return sParameterName[1] === undefined ? true : sParameterName[1];
        }
    }
};


export const fetchWrapper = async (url, init) => {
    const response = await fetch(url, init)
    const json = await response.json()
    return response.ok ? json : Promise.reject(json)
}

export const validateSubscription = (customerId, applicationId) => {
    return fetchWrapper(config.endpoints.subscription, {
        method: 'POST',
        headers: { 'content-type': 'application/json' },
        body: JSON.stringify({ customerId, applicationId })
    })
}

export const isIterable = (obj) => {
    // checks for null and undefined
    if (obj == null) {
        return false;
    }
    return typeof obj[Symbol.iterator] === 'function';
}

const mapToObj = m => {
    return Array.from(m).reduce((obj, [key, value]) => {
        obj[key] = value;
        return obj;
    }, {});
};

const JSONToCSVConvertor = (JSONData, ReportTitle, ShowLabel) => {
   
    const arrData = typeof JSONData !== 'object' ? JSON.parse(JSONData) : JSONData;
    let CSV = 'sep=|\r\n\n'
    let row = ""
    if (ShowLabel) {
        //This loop will extract the label from 1st index of on array
        for (let index in arrData[0])
            row += index + '|';

        row = row.slice(0, -1);
        //append Label row with line break
        CSV += row + '\r\n';
    }

    //1st loop is to extract each row
   
    for (let i = 0; i < arrData.length; i++) {
        row = "";
        try {
            //2nd loop will extract each column and convert it in string comma-seprated
            for (let index in arrData[i]){
               
                row +=  JSON.stringify(arrData[i][index]) + '|';
                row.slice(0, row.length - 1);
            }
        } catch (error) {
               
        }
        //add a line break after each row
        CSV += row + '\r\n';
    }

    if (CSV === '') {
        alert("Invalid data");
        return;
    }

    //Generate a file name
    ReportTitle.replace(/ /g, "_");

    //Initialize file format you want csv or xls
    const uri = 'data:text/csv;charset=utf-8,' + escape(CSV);

    const link = document.createElement("a");
    link.href = uri;
    //set the visibility hidden so it will not effect on your web-layout
    link.style = "visibility:hidden";
    link.download = ReportTitle + ".csv";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}

const formatMilliseconds = (inputValue) => {        
    var milliSeconds = Number(inputValue) / 1000;
    var h = Math.floor(milliSeconds / 3600);
    var m = Math.floor(milliSeconds % 3600 / 60);
    var s = Math.floor(milliSeconds % 3600 % 60);

    var hDisplay = h < 10 ? '0'+h+':' : h+':';
    var mDisplay = m < 10 ? '0'+m+':' : m+':';
    var sDisplay = s < 10 ? '0'+s : s;
    return hDisplay + mDisplay + sDisplay;         
}

export default {
    isIterable,
    getParameterByName,
    fetchWrapper,
    validateSubscription,
    mapToObj,
    JSONToCSVConvertor,
    formatMilliseconds
}