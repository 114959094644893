import React, { useEffect,useState } from 'react'
import { useHistory } from 'react-router-dom'
import {
    Container
} from 'reactstrap'
import Header from '../../../header/Header';
import Footer from '../../../footer/Footer'
import UserDetails from '../UserDetails/UserDetails'
import { useParams } from "react-router-dom";
import backendAPI from '../../../../services/backend'
import AlertModal from '../../../Modals/alertModal/AlertModal'
import ConfirmationModal from '../../../Modals/confirmationModal/ConfirmationModal'
import WaitingModal from '../../../Modals/waitingModal/WaitingModal'
import constants from '../../../../constants';

import './UserUpdate.css'
import { SelectPanel } from 'react-multi-select-component';

const UserUpdate = (props) => {
    const history = useHistory()
    const { id } = useParams()
    const [isWaitingModalOpen, setIsWaitingModalOpen] = useState(false)
    const [error, setError] = useState()
    const [message, setMessage] = useState()
    const [promptHeader, setPromptHeader] = useState()
    const [role, setRole] = useState()

    useEffect(() => { // init load
       
        //setRole(sessionStorage.getItem(constants.R2S_USER_ROLE));
    }, [])

    const onSubmit = async (user) => {
        try {
        setIsWaitingModalOpen(true)
        setPromptHeader('Updating user');
       
        const userUpdateResult = await backendAPI.updateUser(sessionStorage.getItem(constants.GC_TOKEN), id, user)
        setIsWaitingModalOpen(false)
        setMessage(`The user ${user.user[0].label} has been updated successfully`)
        setTimeout(() => {
            history.push('/management/users/');
          }, 2000);
    } catch (error) {
        setError(`An error occured while fetching data:${JSON.stringify(error.message)}`)
       
    } finally {
        setIsWaitingModalOpen(false)
    }
    }

    const onCancel = async () => {
        history.push('/management/users/')
    }

    return (
        <div id="component-wrap">
            <Header role={role}/>
            <div id="main">
                    <WaitingModal
                    isOpen={isWaitingModalOpen}
                    header={promptHeader}
                     />
                    <ConfirmationModal
                    isOpen={!!message}
                    header='Success!'
                    toggle={() => { setMessage(undefined) }}
                    body={message}
                    />
                    <AlertModal
                    isOpen={!!error}
                    header='Error'
                    toggle={() => { setError(undefined) }}
                    body={error}
                    />
                     < br />
                     <label className = "title-name">Update User</label>
                <hr className = "hori-line"/>
                    <UserDetails
                        userId={id? id : ''}
                        okButtonText='Update'
                        cancelButtonText='Cancel'
                        submit={onSubmit}
                        cancel={onCancel}
                    />
            </div>
            <Footer version="0.1" />
        </div>
    )
}

export default UserUpdate